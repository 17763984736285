/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react';

export const NavConfigContext = createContext();


export const NavConfigProvider = ({ children }) => {
    const [navConfigToogle, setNavConfigToogle] = useState(
        () => JSON.parse(localStorage.getItem('navconfigtoogle')) ?? true
    );

    useEffect(() => {
        localStorage.setItem('navconfigtoogle', navConfigToogle);
    }, [navConfigToogle]);

    const handleToggle = (token, navigate) => {
        if (token) {
            setNavConfigToogle((prevValue) => !prevValue);
            navigate(navConfigToogle ? `/tickets` : '/');
        } else {
            navigate('/user/login');
        }
    };

    return (
        <NavConfigContext.Provider value={{ navConfigToogle, handleToggle }}>
            {children}
        </NavConfigContext.Provider>
    );
};



