/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, List, Link, Drawer, Collapse, ListItemText, ListItemIcon, ListItemButton, useTheme, ListItem } from '@mui/material';
// config
import { googleLogout } from '@react-oauth/google';
import { ChevronRight } from '@mui/icons-material';
import { NAVBAR } from '../../config';
// components
import useAuth from '../../hooks/useAuth';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { NavConfigContext } from '../../contexts/ToogleContext';
import { IconButtonAnimate } from '../../components/animate';
import { NavSectionVertical } from '../../components/nav-section';
import Language from './Language';
import whiteLogo from '../../assets/web_book.png';
import SearchBar from './SearchBar';
import Login from '../../assets/login.png';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuMobile({ isOffset, isHome, navConfig }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const token = localStorage.getItem('accessToken');
  const { navConfigToogle, handleToggle } = useContext(NavConfigContext);
  const { logout } = useAuth();

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('currentuser');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('navconfigtoogle');
      localStorage.setItem('navconfigtoogle', true);
      googleLogout();
    } catch (error) {
      console.error(error);
    }
  };
  const themeDarkLight = theme?.palette?.mode;
  const logo = (themeDarkLight === 'dark' || !isOffset) ? whiteLogo : Login;
  const colors = (themeDarkLight === 'dark' || !isOffset) ? 'white' : 'black';

  const [showSubItems, setShowSubItems] = useState(false);

  const toggleSubItems = () => {
    setShowSubItems((prev) => !prev);
  };

  console.log(navConfigToogle, 'navConfigToogle')

  return (
    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100% !important',
        overflowX: 'hidden',

      }}>
        <IconButtonAnimate
          onClick={handleDrawerOpen}
          sx={{
            ml: 1,
            ...(isHome && { color: colors }),
            ...(isOffset && { color: colors }),
          }}
        >
          <Iconify icon={'eva:menu-2-fill'} />
        </IconButtonAnimate>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <img src={logo} alt='Imagwe'
            style={{
              width: '200px'
            }}
            onClick={() => navigate('/')}
          />
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <SearchBar isOffset={isOffset} />
        </Box>
      </Box>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: 260,
            backgroundColor: '#1c1c1c',
            paddingTop: '20px',
            color: 'white',
          },
        }}
      >
        <Scrollbar>
          {
            !navConfigToogle && (
              <>
                <List disablePadding>
                  <ListItem button key="home" onClick={toggleSubItems} sx={{ px: 3, py: 1.5, borderBottom: '1px solid #333', color: 'white' }}>
                    <ListItemText primary="Home" primaryTypographyProps={{ color: 'white' }} />
                    <ChevronRight sx={{ color: '#888' }} />
                  </ListItem>

                  {showSubItems && (
                    <>
                      <ListItem button key="all-matches" onClick={() => navigate('/allmatches')} sx={{ px: 3, py: 1.5, borderBottom: '1px solid #333', color: 'white', pl: 6 }}>
                        <ListItemText primary="All Matches" primaryTypographyProps={{ color: 'white' }} />
                      </ListItem>
                      <ListItem button key="leagues" onClick={() => navigate('/allleagues')} sx={{ px: 3, py: 1.5, borderBottom: '1px solid #333', color: 'white', pl: 6 }}>
                        <ListItemText primary="Leagues" primaryTypographyProps={{ color: 'white' }} />
                      </ListItem>
                    </>
                  )}
                </List>
              </>
            )
          }

          <List disablePadding>
            {navConfig?.map((link) => (
              <ListItem
                button
                key={link.title}
                onClick={() => navigate(link.path)}
                sx={{
                  px: 3,
                  py: 1.5,
                  borderBottom: '1px solid #333',
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: 'white'
                }}
              >
                <ListItemText primary={link.title} primaryTypographyProps={{ color: 'white' }} />
                <ChevronRight sx={{ color: '#888' }} />
              </ListItem>
            ))}
          </List>

          {token ? (
            <Box sx={{ px: 3, pt: 3 }}>
              <button
                className="login_btn"
                onClick={handleLogout}
                style={{
                  width: '100%',
                  color: 'white',
                  backgroundColor: '#FF0000',
                  height: '35px',
                  border: 'none',
                  fontWeight: '600',
                  fontSize: '11px'
                }}
              >
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Logout</Link>
              </button>
              <button className="login_btn mt-3"
                style={{
                  width: '100%',
                  color: 'white',
                  backgroundColor: '#FF0000',
                  height: '35px',
                  border: 'none',
                  fontWeight: '600',
                  fontSize: '11px'
                }}
                onClick={() => handleToggle(token, navigate)}>
                Sell your tickets
              </button>
              <button className="login_btn mt-3"
                style={{
                  width: '100%',
                  color: 'white',
                  backgroundColor: '#FF0000',
                  height: '35px',
                  border: 'none',
                  fontWeight: '600',
                  fontSize: '11px'
                }}
                onClick={() => handleToggle(token, navigate)}>
                Buy Now
              </button>
              <Box sx={{ mt: 2 }}>
                <Language id="google_translate_element_mob" />
              </Box>
            </Box>
          ) : (
            <Box sx={{ px: 3, pt: 2 }}>
              <Box>
                <button
                  className="login_btn"
                  style={{
                    width: '100%',
                    color: 'white',
                    backgroundColor: '#FF0000',
                    height: '35px',
                    border: 'none',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                  onClick={() => navigate('/user/login')}
                >
                  Log in
                </button>
                <button
                  className="login_btn mt-3"
                  style={{
                    width: '100%',
                    color: 'white',
                    backgroundColor: '#FF0000',
                    height: '35px',
                    border: 'none',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                  onClick={() => navigate('/user/signup')}
                >
                  Signup
                </button>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Language id="google_translate_element_mob" />
              </Box>
            </Box>
          )}
        </Scrollbar>
        <Box sx={{ px: 3, pt: 3, borderTop: '1px solid #333', mt: 2 }}>
          <ListItem button key="my-account" onClick={() => navigate('/')} sx={{ px: 3, py: 1.5, color: 'white' }}>
            <ListItemText primary="My Account" primaryTypographyProps={{ color: 'white' }} />
            <ChevronRight sx={{ color: '#888' }} />
          </ListItem>
        </Box>
      </Drawer>

    </>
  );
}

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
  isOpen: PropTypes.bool,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
  onOpen: PropTypes.func,
};

function MenuMobileItem({ item, isOpen, onOpen }) {
  const { title, path, icon, children } = item;

  if (children) {
    return (
      <>
        <ListItemStyle onClick={onOpen}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
          <Iconify
            icon={isOpen ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <NavSectionVertical
              navConfig={children}
              sx={{
                '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                  height: 200,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  bgcolor: 'background.neutral',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage:
                    'url(https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png)',
                  '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
                },
              }}
            />
          </Box>
        </Collapse>
      </>
    );
  }

  if (title === 'Documentation') {
    return (
      <ListItemStyle href={path} target="_blank" rel="noopener" component={Link}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        '&.active': {
          color: '#fff',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}
