/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-new */
import { Box, IconButton, CircularProgress } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useEffect, useState } from 'react';

export default function Language(props) {
    const { id } = props;
    const [isInitialized, setIsInitialized] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadScript = () => {
            return new Promise((resolve, reject) => {
                if (document.getElementById('google-translate-script')) {
                    resolve();
                    return;
                }

                const script = document.createElement('script');
                script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
                script.async = true;
                script.id = 'google-translate-script';
                script.onload = () => resolve();
                script.onerror = () => reject(new Error("Script load error"));
                document.body.appendChild(script);
            });
        };

        const googleTranslateElementInit = () => {
            if (!isInitialized && window.google && window.google.translate) {
                setIsInitialized(true);
                setLoading(false);
                new window.google.translate.TranslateElement(
                    {
                        pageLanguage: "en",
                        autoDisplay: "true",
                        includedLanguages: "en,fr,es,ar,gd",
                        layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
                    },
                    id
                );
                setTimeout(updateLanguageOptions, 500); // Initial call to update option text
            }
        };

        const updateLanguageOptions = () => {
            const optionAbbreviations = {
                "en": "EN",
                "fr": "FR",
                "es": "ES",
                "ar": "AR",
                "gd": "GD"
            };

            const translateSelect = document.querySelector('.goog-te-combo');
            if (translateSelect) {
                translateSelect.querySelectorAll('option').forEach(option => {
                    const langCode = option.value;
                    if (optionAbbreviations[langCode]) {
                        option.text = optionAbbreviations[langCode];
                    }
                });
            }
        };

        if (id) {
            loadScript()
                .then(() => {
                    setTimeout(googleTranslateElementInit, 100);
                })
                .catch(error => {
                    console.error(error);
                    setLoading(false);
                });
        }

        // Listen for language change and update initials
        const observer = new MutationObserver(updateLanguageOptions);
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        return () => {
            const script = document.getElementById('google-translate-script');
            if (script) {
                document.body.removeChild(script);
            }
            observer.disconnect();
        };
    }, [id]);

    return (
        <Box display="flex" alignItems="center" position="relative">
            {loading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
                isInitialized && (
                    <IconButton aria-label="language selector" color="inherit" sx={{
                        position: 'absolute',
                        top: '5px',
                        right: 0,
                        zIndex: 100,
                        pointerEvents: 'none',
                        color: 'white',
                    }}>
                        <LanguageIcon />
                    </IconButton>
                )
            )}
            {id && <div id={id} style={{ marginLeft: '8px' }}></div>}
            <style jsx>{`
                .goog-te-combo {
                    background: #FF0000;
                    color: #fff4e4;
                    border: 2px solid white;
                    border-radius: 25px;
                    padding: 10px 6px;
                    font-weight: bold;
                    outline: none;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                }
            `}</style>
        </Box>
    );
}
