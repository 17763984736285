/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import { useLocation, useNavigate, Link } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { useContext, useState } from 'react';
import { Box, AppBar, Toolbar, Typography, alpha, Avatar, Stack, Container, Divider, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
// hooks
import BorderClearIcon from '@mui/icons-material/BorderClear';
import LogoutIcon from '@mui/icons-material/Logout';
import { googleLogout } from '@react-oauth/google';
import useMediaQuery from '@mui/material/useMediaQuery';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import { NavConfigContext } from '../../contexts/ToogleContext';
import cssStyles from '../../utils/cssStyles';
import MenuPopover from '../../components/MenuPopover';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { IconButtonAnimate } from '../../components/animate';
import useAuth from '../../hooks/useAuth';
import { HEADER } from '../../config';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import whiteLogo from '../../assets/web_book.png';
import Login from '../../assets/login.png';
import Language from './Language';
import SearchBar from './SearchBar';
// import CustomHeader from './CustomHeader';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
  // background: 'none !important',
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'md');
  const isHome = pathname === '/';
  const [open, setOpen] = useState(null);
  const { navConfigToogle, handleToggle, setNavConfigToogle } = useContext(NavConfigContext);
  const token = localStorage.getItem('accessToken');
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = JSON.parse(localStorage.getItem('currentuser'));
  const navConfigs = navConfig(navConfigToogle);
  console.log(isOffset, 'isOffsetisOffsetisOffset')

  const handleClose = () => {
    setOpen(null);
  };
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('currentuser');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('navconfigtoogle');
      setNavConfigToogle(true);
      localStorage.setItem('navconfigtoogle', true);
      navigate('/', { replace: true });
      googleLogout();
      await logout();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.up('md'));
  const themeDarkLight = theme?.palette?.mode;
  const logo = (themeDarkLight === 'dark' || !isOffset) ? whiteLogo : Login;
  return (
    <AppBar sx={{
      boxShadow: 0,
      top: { xs: 0, sm: '0px' },
      backgroundColor: themeDarkLight === 'dark' ? "transparent" : 'transparent',
      color: '#000',
      borderBottom: isOffset ? 'none' : '2px solid #FF0000',
    }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          justifyContent: 'space-between',
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
            boxShadow: 10,
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '100% !important',
            margin: '0px',
            padding: '0px',
            overflowX: 'hidden',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              justifyContent: 'normal',
              alignItems: 'normal',
            },
          }}

        >

          <Box style={{ marginLeft: { md: '26px', xs: '0px' } }}>
            <Typography variant="div">
              <Box sx={{ flexGrow: 1 }} />
              {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfigs} />}
              {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfigs} />}
            </Typography>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link to={'/'}>
              <img src={logo} alt='Imagwe'
                style={{
                  width: '200px'
                }}
              />
            </Link>
          </Box>

          <Box sx={{ alignItems: 'center', gap: 2, display: { xs: 'none', md: 'flex' } }}>
            {
              token &&
              <>
                <SearchBar />
              </>
            }
            <Box>
              {
                token ? (
                  <>
                    <IconButtonAnimate
                      onClick={handleOpen}
                      sx={{
                        p: 0,
                        ...(open && {
                          '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                          },
                        }),
                      }}
                    >
                      {token ? (
                        <>
                          <Avatar
                            alt="Profile Image"
                            src={currentUser?.picture || user?.picture || `http://webook-ticket.devssh.xyz${currentUser?.image || `http://webook-ticket.devssh.xyz${user?.image}`}`}
                            sx={{ width: 40, height: 40 }}
                          />
                        </>
                      ) : (
                        <Avatar sx={{ width: 40, height: 40 }}>MR</Avatar>
                      )}
                    </IconButtonAnimate>
                    <MenuPopover
                      open={Boolean(open)}
                      anchorEl={open}
                      onClose={handleClose}
                      sx={{
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: '230px',
                        '& .MuiMenuItem-root': {
                          typography: 'body2',
                          borderRadius: 0.75,
                        },
                      }}
                    >

                      <Stack sx={{ p: 2, width: 220 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Avatar
                            alt="Profile Image"
                            src={currentUser?.picture || user?.picture || `http://webook-ticket.devssh.xyz${currentUser?.image || `http://webook-ticket.devssh.xyz${user?.image}`}`}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle1" noWrap>
                              {currentUser?.first_name || user?.first_name ? currentUser?.first_name || user?.first_name : currentUser?.name || user?.name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                              <Link to={'/'}>View Profile</Link>
                            </Typography>
                          </Box>
                        </Box>

                        <Divider sx={{ borderStyle: 'dashed', mt: 2 }} />

                        <MenuItem
                          onClick={() => {
                            navigate('/order');
                            handleToggle(token, navigate)
                          }}
                        >
                          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box component="span" sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                              <BorderClearIcon />
                            </Box>
                            My Orders
                          </Box>
                        </MenuItem>

                        <Divider sx={{ borderStyle: 'dashed' }} />

                        <MenuItem onClick={handleLogout} sx={{ bgcolor: alpha('#FFC0CB', 0.3) }}>
                          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box component="span" sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                              <LogoutIcon />
                            </Box>
                            Logout
                          </Box>
                        </MenuItem>
                      </Stack>
                    </MenuPopover>
                  </>
                )
                  : (
                    <>
                      <button
                        onClick={() => navigate('/user/login')}
                        style={{
                          backgroundColor: 'transparent',
                          color: '#FF0000',
                          padding: '6px 14px',
                          border: '2px solid #FF0000',
                          borderRadius: '50px',
                          cursor: 'pointer',
                          fontWeight: 'normal',
                          fontSize: '14px',
                          textTransform: 'uppercase',
                        }}
                      >
                        Login
                      </button>
                    </>
                  )}
            </Box>

            <Language id={isMediumScreen ? "google_translate_element_web" : null} />
            <button className="seller_btn" onClick={() => handleToggle(token, navigate)}>
              {navConfigToogle ? 'Sell your tickets' : 'Buy Now'}
            </button>

          </Box>

        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
    // <CustomHeader />
  );
}
