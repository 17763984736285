function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ROLE_LOGIN = '/login';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_ROLE_LOGIN = {
  root: ROOTS_ROLE_LOGIN,
  admin: path(ROOTS_ROLE_LOGIN, '/admin'),
  company: path(ROOTS_ROLE_LOGIN, `/company`),
  clinic: path(ROOTS_ROLE_LOGIN, '/clinic'),
  officer: path(ROOTS_ROLE_LOGIN, '/officer'),
  member: path(ROOTS_ROLE_LOGIN, '/member'),
  register: path(ROOTS_ROLE_LOGIN, '/register'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  element: {
    app: path(ROOTS_DASHBOARD, '/'),
    element: path(ROOTS_DASHBOARD, '/element'),
    editelement: (id) => path(ROOTS_DASHBOARD, `/editelement/${id}`),
    companyProfile: path(ROOTS_DASHBOARD, '/companyProfile'),
    addelement: path(ROOTS_DASHBOARD, '/addelement'),
  },
  magictype: {
    app: path(ROOTS_DASHBOARD, '/'),
    magictype: path(ROOTS_DASHBOARD, '/magictype'),
    editmagictype: (id) => path(ROOTS_DASHBOARD, `/editmagictype/${id}`),
    addmagictype: path(ROOTS_DASHBOARD, '/addmagictype'),
  },
  tag: {
    app: path(ROOTS_DASHBOARD, '/'),
    tag: path(ROOTS_DASHBOARD, '/tag'),
    edittag: (id) => path(ROOTS_DASHBOARD, `/edittag/${id}`),
    addtag: path(ROOTS_DASHBOARD, '/addtag'),
  },
  home: {
    app: path(ROOTS_DASHBOARD, '/'),
    home: path(ROOTS_DASHBOARD, '/home'),
  },
  ticketdetail: {
    app: path(ROOTS_DASHBOARD, '/'),
    ticketdetail: (id) => path(ROOTS_DASHBOARD, `/ticketdetail/${id}`),
  },
  review: {
    app: path(ROOTS_DASHBOARD, '/'),
    review: path(ROOTS_DASHBOARD, '/review'),
  },
  user: {
    app: path(ROOTS_DASHBOARD, '/'),
    user: path(ROOTS_DASHBOARD, '/user'),
    edituser: (id) => path(ROOTS_DASHBOARD, `/edituser/${id}`),
    adduser: path(ROOTS_DASHBOARD, '/adduser'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  subadmin: {
    app: path(ROOTS_DASHBOARD, '/'),
    subadmin: path(ROOTS_DASHBOARD, '/subadmin'),
    subadminpermission: path(ROOTS_DASHBOARD, '/subadminpermission'),
    addsubadminpermission: path(ROOTS_DASHBOARD, '/addsubadminpermission'),
    editsubadmin: (id) => path(ROOTS_DASHBOARD, `/editsubadmin/${id}`),
    editsubadminpermission: (id) => path(ROOTS_DASHBOARD, `/editsubadminpermission/${id}`),
    addsubadmin: path(ROOTS_DASHBOARD, '/addsubadmin'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  event: {
    app: path(ROOTS_DASHBOARD, '/'),
    event: path(ROOTS_DASHBOARD, '/event'),
    editevent: (id) => path(ROOTS_DASHBOARD, `/editevent/${id}`),
    addevent: path(ROOTS_DASHBOARD, '/addevent'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  league: {
    app: path(ROOTS_DASHBOARD, '/'),
    league: path(ROOTS_DASHBOARD, '/league'),
    editleague: (id) => path(ROOTS_DASHBOARD, `/editleague/${id}`),
    addleague: path(ROOTS_DASHBOARD, '/addleague'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  team: {
    app: path(ROOTS_DASHBOARD, '/'),
    team: path(ROOTS_DASHBOARD, '/team'),
    editteam: (id) => path(ROOTS_DASHBOARD, `/editteam/${id}`),
    addteam: path(ROOTS_DASHBOARD, '/addteam'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  leagueteam: {
    app: path(ROOTS_DASHBOARD, '/'),
    leagueteam: path(ROOTS_DASHBOARD, '/leagueteam'),
    editleagueteam: (id) => path(ROOTS_DASHBOARD, `/editleagueteam/${id}`),
    addleagueteam: path(ROOTS_DASHBOARD, '/addleagueteam'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  matches: {
    app: path(ROOTS_DASHBOARD, '/'),
    matches: path(ROOTS_DASHBOARD, '/matches'),
    addmatcheresult: (id) => path(ROOTS_DASHBOARD, `/addmatcheresult/${id}`),
    editmatches: (id) => path(ROOTS_DASHBOARD, `/editmatches/${id}`),
    addmatches: path(ROOTS_DASHBOARD, '/addmatches'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  matcheticket: {
    app: path(ROOTS_DASHBOARD, '/'),
    matcheticket: path(ROOTS_DASHBOARD, '/matcheticket'),
    editmatcheticket: (id) => path(ROOTS_DASHBOARD, `/editmatcheticket/${id}`),
    addmatcheticket: path(ROOTS_DASHBOARD, '/addmatcheticket'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  tickets: {
    app: path(ROOTS_DASHBOARD, '/'),
    tickets: path(ROOTS_DASHBOARD, '/tickets'),
    addtickets: path(ROOTS_DASHBOARD, '/addtickets'),
    edittickets: (id) => path(ROOTS_DASHBOARD, `/edittickets/${id}`),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  myorder: {
    app: path(ROOTS_DASHBOARD, '/'),
    order: path(ROOTS_DASHBOARD, '/order'),
  },
  bankinfo: {
    app: path(ROOTS_DASHBOARD, '/'),
    bankinfo: path(ROOTS_DASHBOARD, '/bankinfo'),
  },
  buyerandseller: {
    app: path(ROOTS_DASHBOARD, '/'),
    buyerandseller: path(ROOTS_DASHBOARD, '/buyerandseller'),
  },
  category: {
    app: path(ROOTS_DASHBOARD, '/'),
    category: path(ROOTS_DASHBOARD, '/category'),
    editcategory: (id) => path(ROOTS_DASHBOARD, `/editcategory/${id}`),
    addcategory: path(ROOTS_DASHBOARD, '/addcategory'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  slot: {
    app: path(ROOTS_DASHBOARD, '/'),
    slot: (id) => path(ROOTS_DASHBOARD, `/slot/${id}`),
    editslot: (id) => path(ROOTS_DASHBOARD, `/editslot/${id}`),
    addslot: path(ROOTS_DASHBOARD, '/addslot'),
    addslotById: (id) => path(ROOTS_DASHBOARD, `/addslot/${id}`),
  },
  character: {
    app: path(ROOTS_DASHBOARD, '/'),
    character: path(ROOTS_DASHBOARD, '/character'),
    editcharacter: (id) => path(ROOTS_DASHBOARD, `/editcharacter/${id}`),
    addcharacter: path(ROOTS_DASHBOARD, '/addcharacter'),
  },
  appointment: {
    app: path(ROOTS_DASHBOARD, '/'),
    appointment: path(ROOTS_DASHBOARD, '/appointment'),
    editappointment: (id) => path(ROOTS_DASHBOARD, `/editappointment/${id}`),
    appointmentdetail: (id) => path(ROOTS_DASHBOARD, `/appointmentdetail/${id}`),
    addappointment: path(ROOTS_DASHBOARD, '/addappointment'),
  },
  card: {
    app: path(ROOTS_DASHBOARD, '/'),
    card: path(ROOTS_DASHBOARD, '/card'),
    editcard: (id) => path(ROOTS_DASHBOARD, `/editcard/${id}`),
    carddetail: (id) => path(ROOTS_DASHBOARD, `/carddetail/${id}`),
    addcard: path(ROOTS_DASHBOARD, '/addcard'),
  },

  general: {
    app: path(ROOTS_DASHBOARD, '/'),
    subscription: path(ROOTS_DASHBOARD, '/subscription'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   new: path(ROOTS_DASHBOARD, '/blog/new'),
  //   view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
  //   demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  // },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
