/* eslint-disable arrow-body-style */
/* eslint-disable eqeqeq */
/* eslint-disable no-else-return */
// import { Icon } from '@iconify/react';
// import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const getNavConfig = (userRole, permissionSubAdmin) => {
  if (userRole === 1) {
    return [
      {
        items: [
          // { title: 'Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" /> },
          { title: 'User', path: PATH_DASHBOARD.user.user, icon: ICONS.user },
          { title: 'Sub Admin', path: PATH_DASHBOARD.subadmin.subadmin, icon: ICONS.user },
          { title: 'Category', path: PATH_DASHBOARD.category.category, icon: ICONS.booking },
          // { title: 'Event', path: PATH_DASHBOARD.event.event, icon: <PeopleAltIcon /> },
          { title: 'League', path: PATH_DASHBOARD.league.league, icon: ICONS.calendar },
          { title: 'Team', path: PATH_DASHBOARD.team.team, icon: <PeopleAltIcon /> },
          { title: 'Matches', path: PATH_DASHBOARD.matches.matches, icon: <PeopleAltIcon /> },
          { title: 'Add Tickets', path: PATH_DASHBOARD.matcheticket.matcheticket, icon: <PeopleAltIcon /> },
          { title: 'Admin Tickets', path: PATH_DASHBOARD.buyerandseller.buyerandseller, icon: <PeopleAltIcon /> },
        ],
      },
    ];
  } else if (userRole === 2) {
    console.log(permissionSubAdmin, 'permissionSubAdmin')
    return [
      {
        items: [
          // {
          //   title: 'Dashboard',
          //   path: '/dashboard/home',
          //   icon: <Icon icon="ri:dashboard-line" />
          // },
          permissionSubAdmin?.user_view === 1 && {
            title: 'User',
            path: PATH_DASHBOARD.user.user,
            icon: ICONS.user
          },
          permissionSubAdmin?.subadmin_view === 1 && {
            title: 'Sub Admin',
            path: PATH_DASHBOARD.subadmin.subadmin,
            icon: ICONS.user
          },
          permissionSubAdmin?.category_view === 1 && {
            title: 'Category',
            path: PATH_DASHBOARD.category.category,
            icon: ICONS.booking
          },
          // permissionSubAdmin?.event_view === 1 && {
          //   title: 'Event',
          //   path: PATH_DASHBOARD.event.event,
          //   icon: <PeopleAltIcon />
          // },
          permissionSubAdmin?.league_view === 1 && {
            title: 'League',
            path: PATH_DASHBOARD.league.league,
            icon: ICONS.calendar
          },
          permissionSubAdmin?.team_view === 1 && {
            title: 'Team',
            path: PATH_DASHBOARD.team.team,
            icon: <PeopleAltIcon />
          },
          permissionSubAdmin?.league_match_view === 1 && {
            title: 'Matches',
            path: PATH_DASHBOARD.matches.matches,
            icon: <PeopleAltIcon />
          },
          permissionSubAdmin?.match_ticket_view === 1 && {
            title: 'Add Tickets',
            path: PATH_DASHBOARD.matcheticket.matcheticket,
            icon: <PeopleAltIcon />
          },
        ].filter(Boolean),
      },
    ];
  }
  else {
    return []
  }
};

export default getNavConfig;
