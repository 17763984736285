/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, Box } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Image from '../../components/Image';
import whiteLogo from '../../assets/web_book.png';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Quick Links',
    children: [
      { name: 'Home', href: '/' },
      { name: 'Browse Tickets', href: 'allmatches' },
      { name: 'FAQs', href: '#' },
    ],
  },
  {
    headline: 'Company',
    children: [
      { name: 'About Us', href: 'about' },
      { name: 'Careers', href: '#' },
      { name: 'Blog', href: '#' },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms of Service', href: 'termsandconditions' },
      { name: 'Privacy Policy', href: 'termsandconditions' },
      { name: 'Refund Policy', href: 'refundpolicy' },
      { name: 'Cookie Policy', href: '#' },
    ],
  },
  {
    headline: 'Customer Support',
    children: [
      { name: 'Contact Us', href: 'contact' },
      { name: '📞 Call Us: [Phone Number]', href: '#' },
      { name: '✉️ Email: [Email Address]', href: '#' },
      { name: '💬 Live Chat', href: '#' },
    ],
  },
];


const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#081224',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',
  height: '60vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  [theme.breakpoints.down('sm')]: {
    height: 'auto', // Adjust height for smaller screens
  },
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle className="pb-2">
      <Divider />
      <Container maxWidth="xl">
        <Grid container justifyContent="center" sx={{ position: 'relative', zIndex: 2 }} spacing={3}>
          {/* Logo */}
          <Grid item xs={12} sm={4} md={2} display="flex" justifyContent={{ xs: 'center', md: 'start' }}>
            <Box sx={{ width: 200, backgroundColor: '#081224', textAlign: { xs: 'center', md: 'left' } }}>
              <Image visibleByDefault disabledEffect alt="login" src={whiteLogo} />
            </Box>
          </Grid>

          {/* Links */}
          <Grid item xs={12} sm={8} md={10}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', sm: 'row' }}
              flexWrap="wrap"
              justifyContent="space-between"
              className="text-white text-md-left text-center"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2} marginLeft="0px !important">
                  <Typography
                    className="text-orange"
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                    }}
                  >
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      className="footer-link mt-0"
                      component={RouterLink}
                      sx={{
                        display: 'block',
                        padding: '0.5rem',
                        borderRadius: '4px',
                      }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>

          {/* Chat Icon */}
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            display="flex"
            justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
            className="pt-5 d-flex align-items-center gap-2"
          >
            <Typography
              component="p"
              variant="body2"
              className="text-white"
              sx={{
                display: 'flex',
                gap: '5px',
                fontSize: 13,
                textAlign: 'center',
                padding: '0.5rem',
                borderRadius: '50%',
                backgroundColor: '#49B93B',
                cursor: 'pointer',
                color: 'white',
                width: '40px',
                height: '40px',
              }}
            >
              <ChatIcon />
            </Typography>
            <span
              style={{
                background: '#fff',
                borderRadius: '20px',
                padding: '10px 10px',
                color: '#000',
                fontSize: '12px',
                cursor: 'pointer',
              }}
            >
              Presale Chat
            </span>
          </Grid>

          {/* Copyright */}
          <Grid item xs={12} md={8} className="pt-5 pb-3">
            <Typography
              component="p"
              variant="body2"
              className="text-white"
              sx={{
                fontSize: 13,
                textAlign: 'center',
                padding: '0.5rem',
                paddingTop: '1rem',
                borderTop: '1px solid white',
              }}
            >
              ©2024 HNH Soft Tech Solutions - All rights reserved.
            </Typography>
          </Grid>

          {/* Scroll to top */}
          <Grid item xs={12} md={2} display="flex" justifyContent="center" className="pt-5 pb-3">
            <Typography
              component="p"
              variant="body2"
              className="text-white"
              sx={{
                fontSize: 13,
                textAlign: 'center',
                padding: '0.5rem',
                borderRadius: '0px',
                backgroundColor: '#FF0000',
                cursor: 'pointer',
                color: 'white',
                width: '40px',
                height: '40px',
              }}
            >
              <KeyboardArrowUpIcon />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
