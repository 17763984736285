/* eslint-disable no-nested-ternary */
import { useMediaQuery, TextField, Autocomplete, CircularProgress, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router";
import axiosInstance from "../../utils/axios";

export default function SearchBar(props) {
    const { isOffset = false } = props || {};
    const navigate = useNavigate();
    const theme = useTheme();
    const themeDarkLight = theme?.palette?.mode;
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/user/match');
                setOptions(response?.data?.matches);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const toggleInputVisibility = () => {
        setInputVisible(!inputVisible);
    };


    const filteredOptions = options?.filter((option) => option?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()));

    const handleOptionSelect = (event, value) => {
        if (value) {
            setInputValue('');
            navigate(`/allmatches/${value?.id}`, { state: { filteredOptions } });
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && inputValue) {
            setInputValue('');
            navigate(`/allmatches/${inputValue?.id}`, { state: { filteredOptions } });
        }
    };
    const colors = (themeDarkLight === 'dark' || !isOffset) ? 'white' : 'black';
    return (
        <>
            {isMobile && !inputVisible ? (
                <SearchIcon sx={{ color: colors, cursor: 'pointer' }} onClick={toggleInputVisibility} />
            ) : (
                <Autocomplete
                    freeSolo
                    options={filteredOptions}
                    onInputChange={(event, newValue) => setInputValue(newValue)}
                    onChange={handleOptionSelect}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            placeholder="Search"
                            onKeyPress={handleKeyPress}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress size={20} sx={{ color: 'black' }} />
                                        ) : (
                                            isMobile && (
                                                <CloseIcon
                                                    sx={{ color: colors, cursor: 'pointer' }}
                                                    onClick={toggleInputVisibility}
                                                />
                                            )
                                        )}
                                    </>
                                ),
                            }}
                            sx={{
                                color: 'black',
                                width: isMobile ? (inputVisible ? '150px' : '0') : '200px',
                                maxWidth: isMobile ? '150px' : '200px',
                                transition: 'width 0.5s ease',
                                input: {
                                    color: 'black',
                                },
                                '.MuiInputBase-root': {
                                    backgroundColor: 'transparent',
                                    borderBottom: '1px solid #fff',
                                },
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} style={{ padding: '8px', borderBottom: '1px solid #fff' }}>
                            {option.name}
                        </li>
                    )}
                />
            )}
        </>
    )
}