/* eslint-disable prefer-const */
/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import { Box, Stack } from '@mui/material';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
import { NavConfigContext } from '../../contexts/ToogleContext';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { navConfigToogle } = useContext(NavConfigContext);

  return (
    <>
      <Stack sx={{ minHeight: 1 }}>
        <MainHeader navConfigToogle={navConfigToogle} />
        <Outlet />
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            textAlign: 'center',
            position: 'relative',
            backgroundImage: 'url(/images/footer.png)',
          }}
        >
          <MainFooter />
        </Box>
      </Stack >
    </>
  );
}
